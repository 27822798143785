var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{ref:"changePasswordForm",attrs:{"errors":_vm.errors},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var isValid = ref.isValid;
return [_c('portal-target',{attrs:{"name":"notificationAlertChangePasswordForm"}}),_c('x-form-group',{attrs:{"title":"Contraseña actual","required":""}},[_c('FormulateInput',{attrs:{"name":"current","type":"password","disabled":isLoading,"validation":"bail|required","validation-messages":{
        required: 'La contraseña actual es requerida'
      }}})],1),_c('x-form-group',{attrs:{"title":"Nueva contraseña","required":""}},[_c('FormulateInput',{attrs:{"name":"password","type":"password","disabled":isLoading,"placeholder":"Ingresa una nueva contraseña","validation":"bail|required|min:10,length|max:32,length|noRepeatingCharacters|notConsecutiveNumbers|atLeastOneMayus|atLeastOneSymbol|atLeastOneNumber","validation-rules":_vm.$getPasswordValidationRules,"validation-messages":_vm.$getPasswordValidationMessages}})],1),_c('x-form-group',{attrs:{"title":"Repetir contraseña","required":""}},[_c('FormulateInput',{attrs:{"name":"password_confirmation","type":"password","disabled":isLoading,"placeholder":"Repite la nueva contraseña","validation":"bail|required|min:6|max:20|confirm:password","validation-messages":{
        required: 'Debes confirmar la nueva contraseña',
        confirm: 'La confirmación de contraseña no coincide con la nueva contraseña'
      }}})],1),_c('x-form-footer-buttons',{attrs:{"show-cancel":isValid,"disabled":!isValid || isLoading,"loading":isLoading,"isEditing":true,"isCreating":false},on:{"on-cancel":_vm.reset,"on-click-update":_vm.submit}})]}}]),model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="card mb-4">
    <div class="card-header">Detalles de la cuenta</div>
    <div class="card-body">
      <FormulateForm>
        <!-- Nombres -->
        <b-form-group>
          <template #label>
            <label class="small font-weight-bolder">Nombre(s)</label>
          </template>
          <b-form-input
            :value.sync="authUser.nombre"
            readonly
            placeholder="Ingresa tus nombres"
          ></b-form-input>
        </b-form-group>

        <b-row>
          <!-- Primer apellido -->
          <b-col>
            <b-form-group>
              <template #label>
                <label class="small font-weight-bolder">Primer apellido</label>
              </template>
              <b-form-input
                :value.sync="authUser.primer_apellido"
                readonly
                placeholder="Ingresa tu primer apellido"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Segundo apellido -->
          <b-col>
            <b-form-group>
              <template #label>
                <label class="small font-weight-bolder">Segundo apellido</label>
              </template>
              <b-form-input
                :value.sync="authUser.segundo_apellido"
                readonly
                placeholder="Ingresa tu segundo apellido"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Profesión -->
        <b-form-group>
          <template #label>
            <label class="small font-weight-bolder">Profesión</label>
          </template>
          <b-form-input :value.sync="authUser.profesion" readonly></b-form-input>
        </b-form-group>

        <!-- Número de empleado -->
        <b-form-group v-if="authUser.numero_empleado">
          <template #label>
            <label class="small font-weight-bolder">Número de empleado</label>
          </template>
          <b-input :value.sync="authUser.numero_empleado" readonly placeholder="#2342"></b-input>
        </b-form-group>

        <!-- Fecha de ingreso -->
        <b-form-group>
          <template #label>
            <label class="small font-weight-bolder">Fecha ingreso</label>
          </template>

          <b-form-input
            :value="$customFormatDate(authUser.fecha_ingreso, 'DD-MMMM-YYYY')"
            readonly
            placeholder="06/10/1988"
          ></b-form-input>
        </b-form-group>

        <!-- Correo electrónico -->
        <b-form-group>
          <template #label>
            <label class="small font-weight-bolder">Correo electrónico</label>
          </template>
          <b-form-input
            :value.sync="$store.state.userModule.authUser.email"
            readonly
            placeholder="micorreo@empresa.com"
          ></b-form-input>
        </b-form-group>

        <!-- Save changes button-->
        <!-- <div class="form-row">
          <div class="col d-flex justify-content-end">
            <button disabled class="btn btn-warning" type="button">
              Guardar cambios
            </button>
          </div>
        </div> -->
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileAccountForm',

  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser' })
  }
}
</script>

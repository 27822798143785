<template>
  <FormulateForm
    ref="changePasswordForm"
    v-model="formValues"
    @submit="submit"
    :errors="errors"
    #default="{ isLoading, isValid }"
  >
    <portal-target name="notificationAlertChangePasswordForm" />

    <!--(Contraseña actual)-->
    <x-form-group title="Contraseña actual" required>
      <FormulateInput
        name="current"
        type="password"
        :disabled="isLoading"
        validation="bail|required"
        :validation-messages="{
          required: 'La contraseña actual es requerida'
        }"
      />
    </x-form-group>
    <!--(Nueva contraseña)-->
    <x-form-group title="Nueva contraseña" required>
      <FormulateInput
        name="password"
        type="password"
        :disabled="isLoading"
        placeholder="Ingresa una nueva contraseña"
        validation="bail|required|min:10,length|max:32,length|noRepeatingCharacters|notConsecutiveNumbers|atLeastOneMayus|atLeastOneSymbol|atLeastOneNumber"
        :validation-rules="$getPasswordValidationRules"
        :validation-messages="$getPasswordValidationMessages"
      />
    </x-form-group>
    <!--(Confirmar nueva contraseña)-->
    <x-form-group title="Repetir contraseña" required>
      <FormulateInput
        name="password_confirmation"
        type="password"
        :disabled="isLoading"
        placeholder="Repite la nueva contraseña"
        validation="bail|required|min:6|max:20|confirm:password"
        :validation-messages="{
          required: 'Debes confirmar la nueva contraseña',
          confirm: 'La confirmación de contraseña no coincide con la nueva contraseña'
        }"
      />
    </x-form-group>

    <x-form-footer-buttons
      :show-cancel="isValid"
      :disabled="!isValid || isLoading"
      :loading="isLoading"
      :isEditing="true"
      :isCreating="false"
      @on-cancel="reset"
      @on-click-update="submit"
    ></x-form-footer-buttons>
  </FormulateForm>
</template>

<script>
const formValues = () => ({
  current: '',
  password: '',
  password_confirmation: ''
})

export default {
  name: 'ProfileChangePasswordForm',

  data () {
    return {
      formValues: formValues(),
      errors: {}
    }
  },

  watch: {
    formValues () {
      this.errors = {}
    }
  },

  methods: {
    async submit () {
      const getval = await this.$store.dispatch('userModule/updatePassword', {
        id: this.$store.getters['userModule/getAuthUser'].id,
        payload: this.formValues
      })

      this.errors = getval.error ? { ...getval.data } : false

      this.$alert(getval, 'Actualización de contraseña', 'notificationAlertChangePasswordForm', {
        closeOnTimeout: false
      })

      if (getval.error) return

      this.reset()
    },

    reset () {
      document.activeElement.blur()
      this.formValues = formValues()
      this.errors = {}
      this.$refs.changePasswordForm.hideErrors()
    }
  }
}
</script>
